import axios from 'axios'
import { useCallback } from 'react'

export interface FreightSimulationData {
  packages: Array<{
    seller: string
    deliveryTypes: Array<{
      type: string
      value: number
      shippingEstimate: string
    }>
  }>
}

export interface Item {
  id: string
  seller: string
  quantity: number
}

export function useFreightSimulation() {
  const getFreightSimulation = useCallback(
    async (
      items: Item[],
      cep: string
    ): Promise<FreightSimulationData | undefined> => {
      const body = {
        items,
        postalCode: cep,
        country: 'BRA',
      }

      const response = await axios
        .post<FreightSimulationData>('/api/pdp/simulation', body, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .catch((error) => {
          if (error.response) {
            console.error(
              '[simulation] Server responded with error:',
              error.response.status
            )
          } else if (error.request) {
            console.error('[simulation] No response received:', error.request)
          } else {
            console.error(
              '[simulation] Error setting up the request:',
              error.message
            )
          }
        })

      return response?.data
    },
    []
  )

  return {
    getFreightSimulation,
  }
}

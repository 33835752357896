type CarouselDragProps = {
  enableDrag: boolean
  currentIndex: number
  totalSlides: number
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
  dragStartX: number
  setDragStartX: React.Dispatch<React.SetStateAction<number>>
  isDragging: boolean
  hasDragged: React.MutableRefObject<boolean>
  setDragDistance: React.Dispatch<React.SetStateAction<number>>
  dragStartY?: number
  setDragStartY?: React.Dispatch<React.SetStateAction<number>>
  dragDistance?: number
}

export const useCarouselDrag = ({
  enableDrag,
  currentIndex,
  totalSlides,
  setCurrentIndex,
  setIsDragging,
  dragStartX,
  setDragStartX,
  isDragging,
  hasDragged,
  setDragDistance,
  dragStartY,
  setDragStartY,
  dragDistance,
}: CarouselDragProps) => {
  const SENSITIVITY = 30

  const showSlide = (index: number) => {
    if (index >= totalSlides) {
      setCurrentIndex(0)
    } else if (index < 0) {
      setCurrentIndex(totalSlides - 1)
    } else {
      setCurrentIndex(index)
    }
  }

  const handleMouseDown = (
    e: React.TouchEvent<HTMLUListElement> | React.MouseEvent<HTMLUListElement>
  ) => {
    if (!enableDrag) {
      return
    }

    setIsDragging(true)

    if (e.type === 'touchstart') {
      const touchEvent = e as React.TouchEvent<HTMLUListElement>

      setDragStartX(touchEvent.touches[0].clientX)

      return
    }

    e.preventDefault()

    const mouseEvent = e as React.MouseEvent<HTMLUListElement>

    setDragStartX(mouseEvent.clientX)
  }

  const handleMouseMove = (
    e: React.TouchEvent<HTMLUListElement> | React.MouseEvent<HTMLUListElement>
  ) => {
    if (!isDragging || !enableDrag) {
      return
    }

    let clientX

    if (e.type === 'touchmove') {
      const touchEvent = e as React.TouchEvent<HTMLUListElement>

      clientX = touchEvent.touches[0].clientX
    } else {
      e.preventDefault()

      const mouseEvent = e as React.MouseEvent<HTMLUListElement>

      clientX = mouseEvent.clientX

      Math.abs(clientX - dragStartX) > SENSITIVITY &&
        (hasDragged.current = true)
    }

    const deltaX = clientX - dragStartX

    setDragDistance(deltaX)
  }

  const handleMouseUp = (
    e: React.TouchEvent<HTMLUListElement> | React.MouseEvent<HTMLUListElement>
  ) => {
    if (!isDragging || !enableDrag) {
      return
    }

    let clientX

    if (e.type === 'touchend') {
      const touchEvent = e as React.TouchEvent<HTMLUListElement>

      clientX = touchEvent.changedTouches[0].clientX
    } else {
      const mouseEvent = e as React.MouseEvent<HTMLUListElement>

      clientX = mouseEvent.clientX
    }

    const deltaX = clientX - dragStartX

    if (deltaX > SENSITIVITY) {
      showSlide(currentIndex - 1)
    } else if (deltaX < -SENSITIVITY) {
      showSlide(currentIndex + 1)
    }

    setIsDragging(false)
    setDragDistance(0)
  }

  const handleMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false)
      setDragDistance(0)
    }
  }

  const handleTouchStart = (e: React.TouchEvent<HTMLUListElement>) => {
    if (!setDragStartY) {
      return
    }

    setDragStartX(e.touches[0].clientX)
    setDragStartY(e.touches[0].clientY)
    setIsDragging(false)
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLUListElement>) => {
    if (!dragStartY) {
      return
    }

    const deltaX = e.touches[0].clientX - dragStartX
    const deltaY = Math.abs(e.touches[0].clientY - dragStartY)

    // Permite o scroll vertical da página
    if (deltaY > Math.abs(deltaX)) {
      return
    }

    // Só ativa o carrossel se o swipe horizontal for maior que o threshold
    if (Math.abs(deltaX) > SENSITIVITY) {
      setIsDragging(true)
      setDragDistance(deltaX)
    }
  }

  const handleTouchEnd = () => {
    if (!isDragging || !dragDistance) return

    if (dragDistance < -SENSITIVITY) {
      setCurrentIndex((prev) => (prev + 1) % totalSlides)
    } else if (dragDistance > SENSITIVITY) {
      setCurrentIndex((prev) => (prev - 1 + totalSlides) % totalSlides)
    }

    setIsDragging(false)
    setDragDistance(0)
  }

  return {
    showSlide,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleMouseLeave,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  }
}
